<template>
  <div>
    <nav v-if="isLoginFirst" class="nav-mobile">
      <ButtonLinkSignup :size="'small'" class="nav-mobile__link-button" />
      <ButtonCtaLogin :size="'x-small'" />
    </nav>
    <nav v-else class="nav-mobile">
      <ButtonLinkLogin :size="'small'" class="nav-mobile__link-button" />
      <ButtonCtaSignup :size="'x-small'" />
    </nav>
  </div>
</template>

<script>
import ButtonLinkLogin from "./UI/ButtonLinkLogin.vue";
import ButtonCtaSignup from "./UI/ButtonCtaSignup.vue";
import ButtonLinkSignup from "@/components/UI/ButtonLinkSignup.vue";
import ButtonCtaLogin from "@/components/UI/ButtonCtaLogin.vue";

export default {
  components: {
    ButtonLinkLogin,
    ButtonCtaSignup,
    ButtonLinkSignup,
    ButtonCtaLogin,
  },
  props: {
    isLoginFirst: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style scoped>
.nav-mobile {
  width: var(--layout-default--width);
  height: 100%;

  display: flex;
  flex-flow: row;
  justify-content: space-between;
  align-items: center;
}
.nav-mobile__link-button {
  width: 167px;
}
</style>
