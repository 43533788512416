<template>
  <div class="nav-mobile-wrapper">
    <NavMobileDefault v-if="isAuth" />
    <NavMobileAnonymus v-else :isLoginFirst="isLoginFirst" />
  </div>
</template>

<script>
import NavMobileAnonymus from "@/components/NavMobileAnonymus.vue";
import NavMobileDefault from "@/components/NavMobileDefault.vue";

import useAuth from "@/composables/useAuth";

export default {
  components: { NavMobileAnonymus, NavMobileDefault },

  props: {
    isLoginFirst: {
      type: Boolean,
      default: false,
    },
  },

  setup() {
    const { isAuth } = useAuth();

    return {
      isAuth,
    };
  },
};
</script>

<style scoped>
.nav-mobile-wrapper {
  width: 100vw;
  height: var(--nav-mobile-height);

  display: flex;
  align-items: center;
  justify-content: center;

  position: fixed;
  z-index: 9999999;
  bottom: 0;
  left: 0;

  background-color: var(--main-bg-color);
  border-top: 1px solid rgba(22, 32, 94, 0.06);
}
</style>
