import { defineAsyncComponent, markRaw } from "vue";

export const getRawAsyncComponent = (imprt) => {
  return markRaw(
    defineAsyncComponent({
      loader: async () => await imprt,
      delay: 0,
    })
  );
};
