<template>
  <nav class="nav-mobile">
    <router-link
      v-for="link in navLinks.mobileDefault"
      :key="link.to"
      :to="link.to"
      :class="['link', 'nav-mobile__link', link.type]"
    >
      <component :is="link.iconComponent" />
    </router-link>
  </nav>
</template>

<script>
import useNavLinks from "@/composables/useNavLinks";

export default {
  setup() {
    const { navLinks } = useNavLinks();

    return {
      navLinks,
    };
  },
};
</script>

<style scoped>
.nav-mobile {
  width: 85%;
  height: 100%;

  display: flex;
  flex-flow: row;
  justify-content: space-between;
  align-items: center;
}
.nav-mobile__link {
  width: 50px;

  display: flex;
  justify-content: center;
  align-items: center;

  opacity: 0.4;
}
.nav-mobile__link.router-link-active,
.nav-mobile__link.cta {
  opacity: 1;
}
</style>
