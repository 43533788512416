<template>
  <ButtonCta
    :to="{ name: ROUTE_LOGIN_NAME }"
    :label="'Zaloguj się'"
    :size="size"
    :caption="caption"
    :isDisabled="isDisabled"
  />
</template>

<script>
import { ROUTE_LOGIN_NAME } from "@/router/constants";
import ButtonCta from "@/components/UI/Base/ButtonCta.vue";

export default {
  components: { ButtonCta },

  props: {
    size: {
      type: String,
      default: "medium",
    },
    caption: {
      type: String,
      default: "",
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
  },

  setup() {
    return {
      ROUTE_LOGIN_NAME,
    };
  },
};
</script>
