import { navLinks } from "@/router/navLinks/";

const desktopHeader = [navLinks.WISHLIST, navLinks.ACCOUNT_RESPONSES];

const desktopHeaderModalMenu = [navLinks.LOGIN];

const desktopFooter = [
  navLinks.LOGIN,
  navLinks.TERMS_AND_CONDITIONS,
  navLinks.PRIVACY_POLICY,
  navLinks.COOKIES,
  navLinks.CONTACT_US,
];

// Mobile links
const mobileDefault = [
  navLinks.LIST_NANNY,
  navLinks.WISHLIST,
  navLinks.ACCOUNT_RESPONSES,
  navLinks.NOTIFICATIONS,
  navLinks.MOBILE_MENU,
];

const mobileMenu = [
  navLinks.LOGIN,
  navLinks.PRIVACY_POLICY,
  navLinks.TERMS_AND_CONDITIONS,
  navLinks.COOKIES,
  navLinks.CONTACT_US,
];

export const navLinksAnonymus = {
  desktopHeader,
  desktopHeaderModalMenu,
  desktopFooter,
  mobileDefault,
  mobileMenu,
};
